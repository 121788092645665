  import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'suffixDate',
  standalone: true,
  pure: true
})
export class SuffixDatePipe implements PipeTransform {
  transform(day: number): string {
    return moment(day, 'D').format('Do');
  }
}
