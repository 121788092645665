<div>
    <h2 mat-dialog-title class="bulk-selection__title">{{'COM.DIALOGS.BULK.BULK_SELECTION' | translate}}</h2>
    <button class="bulk-selection__close-btn" mat-icon-button mat-dialog-close aria-label="Dialog close">
        <mat-icon class="circle-icon">close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography py-5">
    <div class="bulk-selection">
        <div class="row">
            <div class="d-flex mb-5 align-items-center">
                <div class="small-height">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-20"
                        [ngClass]="{'mat-form-field-invalid': invalidFormName === 'bulkValue' || invalidFormName === 'bulkValueOrOptOut'}">
                        <input
                        [disabled]="optOut || (!regConfig?.gateRules?.post_gate_edits?.allow_offer_edit && selectedTabOption.value === TabOptionsEnum.Open)"
                        [(ngModel)]="bulkValue"
                        type="number"
                        validateNumber
                        [min]="minBulkValue"
                        [max]="maxBulkValue"
                        class="mt-1 input-number-no-arrows"
                        matInput placeholder="Enter Value" />
                    </mat-form-field>
                </div>
                <ng-container *ngIf="selectedTabOption.optOut">
                  <div class="ms-5 me-4">
                    <span>{{'COM.LABELS.OR' | translate}}</span>
                  </div>
                  <div>
                    <mat-checkbox (change)="optOutChange($event)" [(ngModel)]="optOut" color="primary"
                        [ngClass]="{'mat-checkbox-invalid': invalidFormName === 'bulkValueOrOptOut'}">{{'COM.CHECKBOX.OPT_OUT' | translate}}</mat-checkbox>
                  </div>
                </ng-container>
            </div>
        </div>

        <div class="bulk-selection_container">
            <!-- ----- -->
            <!-- START -->
            <!-- ----- -->
            <div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <h1 *ngIf="selectedTabOption.name === 'Schedule'" class="content-title">{{'COM.LABELS.START_DAY' | translate}}:</h1>
                        <h1 *ngIf="selectedTabOption.name !== 'Schedule'" class="content-title">{{'COM.LABELS.START_DATE' | translate}}:</h1>
                    </div>
                </div>
                <div class="row small-height mb-5">
                    <!-- ---------- -->
                    <!-- START DATE -->
                    <!-- ---------- -->
                    <lib-ngx-date-range-picker
                        *ngIf="selectedTabOption.name !== 'Schedule'"
                        ngDefaultControl
                        [datePickerType]="selectedType"
                        [title]="'From'"
                        [border]="true"
                        [date]="startDate"
                        [minDate]="minStartDate"
                        [maxDate]="maxStartDate"
                        [disabled]="false"
                        [invalid]="false"
                        [hint]="'MM/DD/YYYY'"
                        class="export-date"
                        [(ngModel)]="startDate"
                        (onClear)="clearStartDate()"
                        (onChange)="startDateChange($event)"
                        >
                    </lib-ngx-date-range-picker>

                    <mat-form-field *ngIf="selectedTabOption.name === 'Schedule'" appearance="outline" subscriptSizing="dynamic" class="w-20">
                        <mat-select [(value)]="startDaySelectedPeriod">
                            <mat-option [value]="option.value" *ngFor="let option of startDayPeriodOptions">{{ option.label
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <h1 class="content-title">{{'COM.LABELS.START_TIME' | translate}}:</h1>
                    </div>
                </div>
                <div class="time">
                    <!-- --------------- -->
                    <!-- START TIME HOUR -->
                    <!-- --------------- -->
                    <div class="time-container">
                      <button (click)="incrementHour('start')" mat-icon-button aria-label="Increase hour"
                          class="time-container_increament">
                          <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>

                      <div class="time-container_textbox small-height">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic"
                            [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                            name="formStartHour">
                            <input [ngModel]="startHour12 | twoDigitFormat" readonly class="time-container_textbox--input" matInput
                                name="startHour" placeholder="Hour" />
                        </mat-form-field>
                      </div>

                      <button (click)="decrementHour('start')" mat-icon-button aria-label="Decrease hour"
                          class="time-container_decreament">
                          <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>
                    </div>

                    <span class="content-colon">:</span>

                    <!-- ----------------- -->
                    <!-- START TIME MINUTE -->
                    <!-- ----------------- -->
                    <div class="time-container">
                      <button (click)="incrementMinuteByFrequency('start')" mat-icon-button
                                aria-label="Increase minute" class="time-container_increament">
                                <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>

                      <div class="time-container_textbox small-height">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic"
                            [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                                name="formStartMinute">
                                <input [ngModel]="startMinute | twoDigitFormat" readonly class="time-container_textbox--input" matInput placeholder="21"
                                    name="startMinute" />
                            </mat-form-field>
                      </div>

                      <button (click)="decrementMinuteByFrequency('start')" mat-icon-button class="time-container_decreament"
                          aria-label="Decrease minute">
                          <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="small-height mat-button-container--toggle mat-button-container--toggle-border">
                        <mat-button-toggle-group [(ngModel)]="startPeriod" name="startPeriod"
                        [ngClass]="{'mat-button-toggle-invalid': invalidFormName === 'startEndTime'}"
                            aria-label="Time Format Toggle">
                            <mat-button-toggle value="am">{{'COM.LABELS.AM' | translate}}</mat-button-toggle>
                            <mat-button-toggle value="pm">{{'COM.LABELS.PM' | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
            <div class="bulk-selection_container--border"></div>
            <!-- --- -->
            <!-- END -->
            <!-- --- -->
            <div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <h1 *ngIf="selectedTabOption.name === 'Schedule'" class="content-title">{{'COM.LABELS.END_DAY' | translate}}:</h1>
                        <h1 *ngIf="selectedTabOption.name !== 'Schedule'" class="content-title">{{'COM.LABELS.END_DATE' | translate}}:</h1>
                    </div>
                </div>
                <div class="row small-height mb-5">
                    <!-- ---------- -->
                    <!-- END DATE -->
                    <!-- ---------- -->
                    <lib-ngx-date-range-picker
                        *ngIf="selectedTabOption.name !== 'Schedule'"
                        ngDefaultControl
                        [datePickerType]="selectedType"
                        [title]="'To'"
                        [border]="true"
                        [date]="endDate"
                        [minDate]="minEndDate"
                        [maxDate]="maxEndDate"
                        [disabled]="false"
                        [invalid]="false"
                        [hint]="'MM/DD/YYYY'"
                        class="export-date"
                        [(ngModel)]="endDate"
                        (onClear)="clearEndDate()"
                        (onChange)="endDateChange($event)"
                        >
                    </lib-ngx-date-range-picker>

                    <mat-form-field  *ngIf="selectedTabOption.name === 'Schedule'" appearance="outline" subscriptSizing="dynamic" class="w-20">
                      <mat-select [(value)]="endDaySelectedPeriod">
                          <mat-option [value]="option.value" *ngFor="let option of endDayPeriodOptions">{{ option.label
                              }}</mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <h1 class="content-title">{{'COM.LABELS.END_TIME' | translate}}:</h1>
                    </div>
                </div>
                <div class="time">
                    <!-- ------------- -->
                    <!-- END TIME HOUR -->
                    <!-- ------------- -->

                    <div class="time-container">
                      <button (click)="incrementHour('end')" mat-icon-button aria-label="Increase hour"
                          class="time-container_increament">
                          <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>

                      <div class="time-container_textbox small-height">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic"
                            [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                                name="formEndHour">
                                <input [ngModel]="endHour12 | twoDigitFormat" readonly class="time-container_textbox--input" matInput
                                    name="endHour" placeholder="Hour" />
                            </mat-form-field>
                      </div>

                      <button (click)="decrementHour('end')" mat-icon-button aria-label="Decrease hour"
                          class="time-container_decreament">
                          <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>
                    </div>

                    <span class="content-colon">:</span>
                    <!-- --------------- -->
                    <!-- END TIME MINUTE -->
                    <!-- --------------- -->
                    <div class="time-container">
                      <button (click)="incrementMinuteByFrequency('end')" mat-icon-button
                              aria-label="Increase minute"  class="time-container_increament">
                              <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>

                      <div class="time-container_textbox small-height">
                        <mat-form-field appearance="outline" name="formEndMinute"
                        [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                            subscriptSizing="dynamic">
                            <input [ngModel]="endMinute | twoDigitFormat" readonly class="time-container_textbox--input" matInput placeholder="Minute"
                                name="endMinute" />
                        </mat-form-field>
                      </div>

                      <button (click)="decrementMinuteByFrequency('end')" mat-icon-button
                          aria-label="Decrease minute" class="time-container_decreament">
                          <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                      </button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="small-height mat-button-container--toggle mat-button-container--toggle-border">
                        <mat-button-toggle-group [(ngModel)]="endPeriod" name="endPeriod"
                        [ngClass]="{'mat-button-toggle-invalid': invalidFormName === 'startEndTime'}"
                            aria-label="Time Format Toggle">
                            <mat-button-toggle value="am">{{'COM.LABELS.AM' | translate}}</mat-button-toggle>
                            <mat-button-toggle value="pm">{{'COM.LABELS.PM' | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="pb-4 pe-5 ps-5 justify-content-between">
    <h3 class="self-align-left red">
        <span *ngIf="invalidFormName">
            {{error}}
        </span>
    </h3>
    <button mat-flat-button
            (click)="apply()"
            color="primary"
            [disabled]="((bulkValue === null || bulkValue === undefined || ((bulkValue < minBulkValue || bulkValue > maxBulkValue) && selectedTabOption.name !== 'Baselines')) && !optOut)
            || ((!startDate || !endDate) && selectedTabOption.name !== 'Schedule')
            || ((!startDaySelectedPeriod || !endDaySelectedPeriod) && selectedTabOption.name === 'Schedule')"
            class="bulk-selection_apply-btn">{{'COM.BUTTONS.APPLY' | translate}}</button>
</mat-dialog-actions>
