import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DARequest } from '@dr-customer-offers-ui/lib-interfaces';
import { BulkType, DetailTableCellModel, NgxIntervalDataGridRowModel, Week, WeekDays } from '@ngx-interval-data-grid';
import * as moment from 'moment';
import { NgxIntervalDataGridService, TableDataTypes } from 'ngx-interval-data-grid';
import { Observable, Subscription, take, tap } from 'rxjs';
import { BulkInput, GroupedData, HeaderButtonTypeEnum, HeaderButtpnType, InputTypeEnum } from '../../../shared/models';
import { DayOfWeekModel, UIState } from '../../../shared/models/utility';
import { DataModelService } from '../../../shared/services/data-model.service';
import { DataViewModelService } from '../../../shared/services/data-vm.service';
import { InternalService } from '../../../shared/services/internal.service';
import { MessageService } from '../../../shared/services/message.service';
import { MixPanelService } from '../../../shared/services/mixpanel.service';
import { ReasonCodeConfirmComponent } from '../../dialogs/reason-code-confirm/reason-code-confirm.component';

@Component({
  selector: 'dr-customer-offers-ui-da-table',
  templateUrl: './da-tab-table.component.html',
  styleUrls: ['./da-tab-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeclaredAvailabilityTabTableComponent implements OnInit, OnDestroy {
  @Input()
  set groupedData(g: GroupedData | null) {
    this._groupedData = g;
    if (!g) return;
    this.timezoneAbbr = moment.tz(g.regConfig ? g.regConfig.timeZone : 'UTC').zoneName();
    this.dataSource.data = g.values ?? [];
    const startDate: Date = new Date((g.selectedDateRange?.start ?? moment(new Date)).format('YYYY-MM-DD'));

    this.dates = [];
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(startDate);
      newDate.setDate(startDate.getDate() + i);
      this.dates.push(newDate.getDate());
    }
  }

  get groupedData(): GroupedData | null {
    return this._groupedData;
  }

  subs = new Subscription();
  UIState = UIState;
  private _groupedData: GroupedData | null = null;
  public bindingValue!: number;
  public editMode$: Observable<boolean> = this.internalService.editMode$;
  private onClick$: Observable<HeaderButtpnType | null> = this.internalService.getOnChange$;

  protected dates: number[] = [];
  public timezoneAbbr!: string;

  // Define column headers and fields
  columns: string[] = WeekDays;
  displayedColumns: string[] = ['time_period', ...this.columns];
  serverData!: NgxIntervalDataGridRowModel[];
  public dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);

  constructor(
    private ngxService: NgxIntervalDataGridService,
    private internalService: InternalService,
    private cdr: ChangeDetectorRef,
    private dataModelService: DataModelService,
    private dataVMService: DataViewModelService,
    private mixPanelService: MixPanelService,
    private messageService: MessageService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.mixPanelService.viewTab('Open');
    this.applyBulk();
    this.subs.add(this.onClick$.subscribe((o) => {
      if (o === HeaderButtonTypeEnum.CONFIRM) {
        this.save();
        this.internalService.setOnChange(null);
      } else if (o === HeaderButtonTypeEnum.CANCEL) {
        this.cancel();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * This method is updated each time you enter a value on any of the input field
   * @param element provides the whole element which is being updated
   * @param event is an KeyboardEvent where we get the value entered.
   * @param week is used to provide the week which we are updating the value for.
   */
  inputToCellValue(element: NgxIntervalDataGridRowModel, inputEvent: FocusEvent, week: string) {
    const target = inputEvent?.target as HTMLInputElement | null;
    const value: number | null = target?.valueAsNumber === undefined || isNaN(target.valueAsNumber) ? null : target?.valueAsNumber;
    const oldData: NgxIntervalDataGridRowModel = (this.groupedData?.values as NgxIntervalDataGridRowModel[]).find(f => f.timePeriodKey === element.timePeriodKey) as NgxIntervalDataGridRowModel;
    const nullReset = oldData[week as Week].value === null  && value === null;
    const isValid = nullReset || (value !== null && value <= (this.groupedData?.regConfig?.maximumValue ?? 0) && value >= (this.groupedData?.regConfig?.minimumValue ?? 0));

    if (value && this.groupedData?.regConfig?.gateRules.full_day_offer_value) {
      this.serverData = [...this.ngxService.updateValueBulk(BulkType.DAY, value, TableDataTypes.DECLARED_AVAILABILITIES, week as Week, undefined, undefined, !isValid)];
      this.dataSource.data = this.serverData;
      this.cdr.detectChanges();
    } else {
      const updatedDataElement = this.ngxService.updateElementValue({
        weekName: week as Week,
        timePeriodKey: element.timePeriodKey,
        value,
        invalid_value: !isValid,
        tableDataType: TableDataTypes.DECLARED_AVAILABILITIES
      });
      this.serverData = [...updatedDataElement];
    }

  }

  /**
   * This method is used when user cancels from edit mode. Here we forget all his previous updates and take data we recieved from the server.
   */
  cancel() {
    this.internalService.getDayOfWeekDataCache.pipe(
      take(1)
    ).subscribe((v: DayOfWeekModel[] | null) => {
      if (!v) return;
      this.dataSource.data = this.dataVMService.cancelEditing(v.filter(f => f.tableType === TableDataTypes.DECLARED_AVAILABILITIES)[0]);
    })
  }

  /**
   * This is the main method used to save the updated data and switch back to the readonly mode
   */

  save() {
    console.warn(' this.differencesData', this.ngxService.unsavedData);

    const hasReasons = this.groupedData?.regConfig?.gateRules &&
          Array.isArray(this.groupedData?.regConfig?.gateRules.post_gate_edits.post_gate_change_reasons) &&
          this.groupedData?.regConfig?.gateRules.post_gate_edits.post_gate_change_reasons.length > 0;
    const provideChangeReason = this.groupedData?.regConfig?.gateRules.post_gate_edits.provide_change_reason;

    // Check if change reason should be provided:
    if(provideChangeReason && hasReasons) {
      console.log(this.groupedData?.regConfig);

      const dialogRef = this.dialog.open(ReasonCodeConfirmComponent, {
        data: {
          postGateChangeReasons: this.groupedData?.regConfig?.gateRules.post_gate_edits.post_gate_change_reasons,
        },
        width: '39rem',
        disableClose: false,
        hasBackdrop: true
      });

      dialogRef.afterClosed().pipe(
        tap((reason:string) =>{
          this.internalService.editMode$.next(false);
          if(reason) {
            const unsavedOffers: DetailTableCellModel[] = this.dataVMService.lookForDSTDuplication(this.ngxService.unsavedData, this.groupedData?.regConfig?.timeZone as string);
            const daOffersToPost: DARequest[] =
            this.dataModelService.getDAOffersToPost(unsavedOffers, this.groupedData?.regConfig, reason);
            this.postOffers(daOffersToPost);
          }else{
            this.cancel();
          }
        }),
        take(1)
      ).subscribe();

    } else {
      this.internalService.editMode$.next(false);
      const unsavedOffers: DetailTableCellModel[] = this.dataVMService.lookForDSTDuplication(this.ngxService.unsavedData, this.groupedData?.regConfig?.timeZone as string);
      const daOffersToPost: DARequest[] =
      this.dataModelService.getDAOffersToPost(unsavedOffers, this.groupedData?.regConfig);
      this.postOffers(daOffersToPost);
    }
  }

  postOffers(offers: DARequest[]) {
    const regId = offers.length ? offers[0].registration_id : '';
    // Refresh data when success or when error (will also clearUnSavedData)
    this.dataVMService.postDAOffers(offers, regId)
      .subscribe({
          next: () => {
            this.messageService.handleSuccess("Successfully submitted offers for a given time period.");
            this.dataVMService.refreshData(TableDataTypes.DECLARED_AVAILABILITIES)
          },
          error: () => this.dataVMService.refreshData(TableDataTypes.DECLARED_AVAILABILITIES)
      });
  }

  applyBulk(): void {
    this.subs.add(this.internalService.getBulkInputs$.subscribe((b: BulkInput | null) => {
      if (!b) return;
      switch (b.bulkType) {
        case BulkType.CUSTOM:
          {
            if (b.inputType === InputTypeEnum.VALUE && (b.value !== null && b.value !== undefined))
              this.serverData = [...this.ngxService.updateValueBulk(BulkType.CUSTOM, b.value, TableDataTypes.DECLARED_AVAILABILITIES, undefined, moment(b.startDateAndTime), moment(b.endDateAndTime))];
          }
          break;
        case BulkType.DAY:
          if (b.value !== null && b.value !== undefined) this.serverData = [...this.ngxService.updateValueBulk(BulkType.DAY, b.value, TableDataTypes.DECLARED_AVAILABILITIES, b.week)];
          break;
        case BulkType.WEEK:
          if (b.value !== null && b.value !== undefined) this.serverData = [...this.ngxService.updateValueBulk(BulkType.WEEK, b.value, TableDataTypes.DECLARED_AVAILABILITIES)];
          break;
      }
      console.warn('this.serverData', this.serverData);
      this.dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);
      this.cdr.detectChanges();
    }));
  }
}
