<dr-customer-offers-ui-global-alert></dr-customer-offers-ui-global-alert>
<global-nav [navUrl]="navUrl" [appLabel]="appTitleValue.value | translate"></global-nav>
<dr-customer-offers-ui-headboard [showEditButton]="selectedTabOption && selectedTabOption.showEditButton ? selectedTabOption.showEditButton : false"></dr-customer-offers-ui-headboard>
<div class="com-container">
  <dr-customer-offers-ui-filters></dr-customer-offers-ui-filters>
  <div
    *ngIf="{
      isLoading: (isLoading$ | async),
      config: (selectedRegConfig$ | async),
      editMode: (editMode$ | async),
      groupedData: (groupedData$ | async),
      scheduleData: (scheduleData$ | async),
      baselineData: (baselinesData$ | async),
      daData: (getGroupedDataWithDV$ | async),
      errorLoadingData: (errorLoadingData$ | async)
    } as vm"
  >
    <ng-container *ngIf="!vm.isLoading; else loadingPage">
      <div *ngIf="!vm.errorLoadingData; else errorMessage">
        <div class="com-container_header">
          <div class="com-container_header--tabs">
            <dr-customer-offers-ui-tabs (tabChange)="tabChanged($event)" [options]="tabOptions"></dr-customer-offers-ui-tabs>
          </div>
          <div class="com-container_header--export">
            <ng-container *ngIf="selectedTabOption.exportFunctionality && !vm.editMode">
              <button mat-stroked-button color="primary" class="download-btn"
              (click)="openExportFunctionalityDialog()">{{ 'COM.BUTTONS.EXPORT_REPORT' | translate }}
              <mat-icon class="lh-9">file_download</mat-icon></button>
            </ng-container>
            <ng-container *ngIf="selectedTabOption.bulkFunctionality && vm.editMode && !(vm.groupedData?.values![0].groupedOffersIntervals && selectedTabOption.name === 'Open')">
              <dr-customer-offers-ui-bulk-offers
                [minuteFrequency]="vm.config?.intervalFrequency"
                [maxBulkValue]="vm.config?.maximumValue!"
                [minBulkValue]="vm.config?.minimumValue!"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [selectedTabOption]="selectedTabOption"
                [scheduleData]="vm.scheduleData"
                [regConfig]="vm.config"
              ></dr-customer-offers-ui-bulk-offers>
            </ng-container>
          </div>
        </div>
        <div class="mt-5">
          <ng-container [ngSwitch]="selectedTabOption.value">
            <ng-container *ngSwitchCase="TabOptionsEnum.Open">
              <dr-customer-offers-ui-open-table [groupedData]="vm.groupedData"></dr-customer-offers-ui-open-table>
            </ng-container>
            <ng-container *ngSwitchCase="TabOptionsEnum.Cleared">
              <dr-customer-offers-ui-cleared-table [groupedData]="vm.groupedData"></dr-customer-offers-ui-cleared-table>
            </ng-container>
            <ng-container *ngSwitchCase="TabOptionsEnum.Schedule">
              <dr-customer-offers-ui-schedule-table [groupedData]="vm.scheduleData"></dr-customer-offers-ui-schedule-table>
            </ng-container>
            <ng-container *ngSwitchCase="TabOptionsEnum.Baselines">
              <dr-customer-offers-ui-baselines-table [groupedData]="vm.baselineData"></dr-customer-offers-ui-baselines-table>
            </ng-container>
            <ng-container *ngSwitchCase="TabOptionsEnum.Declared_Availability">
              <dr-customer-offers-ui-da-table [groupedData]="vm.daData"></dr-customer-offers-ui-da-table>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <ng-template #errorMessage>
        <dr-customer-offers-ui-no-data [title]="errorMessageTitle" [message]="message"></dr-customer-offers-ui-no-data>
      </ng-template>
    </ng-container>
    <ng-template #loadingPage>
      <div class="loading-page">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
<ngx-global-contact-footer></ngx-global-contact-footer>
